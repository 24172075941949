<template>
  <div class="dl-acc">
    <div>
          <TextScroll :isVis="isVis" :dataList="textArr" @handlerNotice="getAnnouncement"></TextScroll>
        </div>
    <div>
      <div class="dl-box">
        <div class="cr-box">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span class="card-title">{{
                $t("RewardCenter.AgentAccount.AgentAccountTitle")
              }}</span>
            </div>
            <div>
              <el-row :gutter="20">
                <el-col :span="13"
                  ><div
                    class="grid-content bg-purple"
                    style="padding-left: 13rem"
                  >
                    <div class="item-t">
                      <div class="tit">
                        {{ $t("RewardCenter.AgentAccount.Balance")
                        }}<el-tooltip
                          class="item"
                          effect="dark"
                          :content="$t('RewardCenter.AgentAccount.BalanceTip')"
                          placement="top-start"
                        >
                          <i class="el-icon-question qtstyle"></i>
                        </el-tooltip>
                      </div>
                      <div class="item-v">${{ formatNum(agentAccData.balance) || 0 }}</div>
                    </div>
                    <div class="item-t">
                      <div class="tit">
                        {{ $t("RewardCenter.AgentAccount.RewardTotal")
                        }}<el-tooltip
                          class="item"
                          effect="dark"
                          :content="
                            $t('RewardCenter.AgentAccount.RewardTotalTip')
                          "
                          placement="top-start"
                        >
                          <i class="el-icon-question qtstyle"></i>
                        </el-tooltip>
                      </div>
                      <div class="item-v">${{ formatNum(tots.total) }}</div>
                    </div>
                    <div class="item-t">
                      <div class="tit">
                        {{ $t("RewardCenter.AgentAccount.MyInvitationLink")
                        }}<el-tooltip
                          class="item"
                          effect="dark"
                          :content="
                            $t('RewardCenter.AgentAccount.MyInvitationLinkTip')
                          "
                          placement="top-start"
                        >
                          <i class="el-icon-question qtstyle"></i>
                        </el-tooltip>
                      </div>
                      <div class="item-v">{{ agentAccData.code }}</div>
                    </div>
                    <!-- <div class="item-t">
                      <div class="tit">
                        {{ $t("RewardCenter.AgentAccount.WithdrawalAccount")
                        }}<el-tooltip
                          class="item"
                          effect="dark"
                          :content="
                            $t('RewardCenter.AgentAccount.WithdrawalAccountTip')
                          "
                          placement="top-start"
                        >
                          <i class="el-icon-question qtstyle"></i>
                        </el-tooltip>
                      </div>
                      <div
                        v-if="tt === null || agentAccData.withdraw_account.Alipay == undefined || agentAccData.withdraw_account.length == 0"
                        style="width: 150px; text-align: left"
                      >
                      <img style="width:35px" src="../../assets/alipay.png" alt="" />
                        
                        <button
                          @click="toWithdrawPage"
                          style="width: 98px"
                          class="cur hv"
                          type="button"
                        >
                          {{ $t("RewardCenter.AgentAccount.BindNow") }}
                        </button>
                      </div>
                      <div v-else class="txacc">
                        <img style="width:35px" src="../../assets/alipay.png" alt="" />
                        <div class="item-v">
                          {{
                            agentAccData.withdraw_account.Alipay.AccountNumber
                          }}
                        </div>
                      </div>
                    </div> -->
                    <!--  -->
                      <!-- <div class="item-t">
                      <div class="tit" style="opacity: 0">銀行賬戶</div>
                      <div
                        v-if="tt === null || agentAccData.withdraw_account.Bank == undefined || agentAccData.withdraw_account.length == 0"
                        style="width: 145px; text-align: left"
                      >
                      <img style="height:28px" src="../../assets/bank.png" alt="" />
                        <button
                          @click="toBankPage"
                          style="width: 98px;margin-left:5px"
                          class="cur hv"
                          type="button"
                        >
                          {{ $t("RewardCenter.AgentAccount.BindNow") }}
                        </button>
                      </div>
                      <div v-else class="txacc">
                        <img style="height:28px" src="../../assets/bank.png" alt="" />
                        <div class="item-v" style="margin-left:5px">
                          {{ agentAccData.withdraw_account.Bank.AccountNumber }}
                        </div>
                      </div>
                    </div> -->
                    <!--  -->

                    <!-- USDT -->
                      <div class="item-t">
                      <!-- <div class="tit" style="opacity: 0">USDT賬戶</div> -->
                      <div class="tit">
                        {{ $t("RewardCenter.AgentAccount.WithdrawalAccount")
                        }}<el-tooltip
                          class="item"
                          effect="dark"
                          :content="
                            $t('RewardCenter.AgentAccount.WithdrawalAccountTip')
                          "
                          placement="top-start"
                        >
                          <i class="el-icon-question qtstyle"></i>
                        </el-tooltip>
                      </div>
                      <div
                        v-if="tt === null || agentAccData.withdraw_account.USDT == undefined ||agentAccData.withdraw_account.length == 0"
                        style="width: 141px; text-align: left"
                      >
                      <img style="height:23px" src="../../assets/USDT.png" alt="" />
                        <button
                          @click="toUSDTPage"
                          style="width: 98px;margin-left:5px"
                          class="cur hv"
                          type="button"
                        >
                          {{ $t("RewardCenter.AgentAccount.BindNow") }}
                        </button>
                      </div>
                      <div v-else class="txacc">
                        <img style="height:23px" src="../../assets/USDT.png" alt="" />
                        <div class="item-v" style="margin-left:5px">
                          {{ agentAccData.withdraw_account.USDT.Address }}
                        </div>
                      </div>
                    </div>
                    <!--  -->
                  </div>
                </el-col>
                <el-col :span="8"
                  ><div class="grid-content bg-purple">
                    <div class="rg-box">
                      <!-- <el-button size="mini" style="margin-right: 0.5rem">{{
                        $t("RewardCenter.AgentAccount.BalanceBtn1")
                      }}</el-button> -->
                      <button
                        @click="toTransferAcc"
                        style="margin-right: 0.5rem"
                        class="cur hv"
                        type="button"
                      >
                        {{ $t("RewardCenter.AgentAccount.BalanceBtn1") }}
                      </button>
                      <div>
                        <!-- <el-button size="mini">{{
                          $t("RewardCenter.AgentAccount.BalanceBtn2")
                        }}</el-button> -->
                        <button
                          @click="toAgentWithdraw"
                          style=""
                          class="cur hv"
                          type="button"
                        >
                          {{ $t("RewardCenter.AgentAccount.BalanceBtn2") }}
                        </button>
                      </div>
                    </div>
                    <div class="rg-box pdt" style="opacity: 0">
                      <el-button size="mini">转出至账户余额</el-button>
                      <div><el-button size="mini">提现</el-button></div>
                    </div>

                    <div class="rg-box pdt">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="$t('RewardCenter.AgentAccount.CopyTip')"
                        placement="top-start"
                      >
                        <i
                          @click="
                            getCopy(
                              'https://dashboard.fotoncard.com/#/pages/register?agent=' +
                                agentAccData.code
                            )
                          "
                          class="el-icon-link"
                          style="
                            font-size: 24px;
                            margin-right: 1rem;
                            cursor: pointer;
                          "
                        ></i>
                      </el-tooltip>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="$t('RewardCenter.AgentAccount.DownloadTip')"
                        placement="top-start"
                      >
                        <!-- <i
                          @click="downs"
                          style="font-size: 24px"
                          class="el-icon-download qtstyle"
                        ></i> -->
                        <svg
                          @click="downs"
                          style="margin-right: 1rem; cursor: pointer"
                          fill="rgb(161,150,170)"
                          t="1660705685412"
                          class="icon"
                          viewBox="0 0 1024 1024"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          p-id="2413"
                          width="24"
                          height="24"
                        >
                          <path
                            d="M201.274096 209.080899 267.600711 209.080899 267.600711 275.407514 201.274096 275.407514 201.274096 209.080899Z"
                            p-id="2414"
                          ></path>
                          <path
                            d="M386.990868 89.692788 81.885985 89.692788l0 305.105906 119.388111 0 0 53.061496 66.326615 0 0-53.061496 119.389134 0 0-66.328661 53.061496 0 0-53.06252-53.061496 0L386.989845 89.692788 386.990868 89.692788 386.990868 89.692788zM320.66323 328.470033 148.2126 328.470033 148.2126 156.019403l172.45063 0L320.66323 328.470033 320.66323 328.470033z"
                            p-id="2415"
                          ></path>
                          <path
                            d="M201.274096 752.963027 267.600711 752.963027 267.600711 819.290665 201.274096 819.290665 201.274096 752.963027Z"
                            p-id="2416"
                          ></path>
                          <path
                            d="M745.156224 209.080899 811.483862 209.080899 811.483862 275.407514 745.156224 275.407514 745.156224 209.080899Z"
                            p-id="2417"
                          ></path>
                          <path
                            d="M506.378979 508.837984 506.378979 580.51342 572.706617 580.51342 572.706617 514.185782 625.76709 514.185782 625.76709 447.859167 572.706617 447.859167 572.706617 394.798694 506.378979 394.798694 506.378979 448.195835 506.378979 508.837984Z"
                            p-id="2418"
                          ></path>
                          <path
                            d="M506.378979 751.404532 506.378979 819.291688 572.706617 819.291688 572.706617 751.404532 572.706617 699.901531 625.76709 699.901531 625.76709 633.573893 570.660005 633.573893 506.378979 633.573893 506.378979 690.762383 506.378979 751.404532Z"
                            p-id="2419"
                          ></path>
                          <path
                            d="M572.706617 819.291688 625.76709 819.291688 625.76709 872.352161 572.706617 872.352161 572.706617 819.291688Z"
                            p-id="2420"
                          ></path>
                          <path
                            d="M440.052364 872.352161 440.052364 938.679799 510.017856 938.679799 572.706617 938.679799 572.706617 872.352161 510.017856 872.352161 440.052364 872.352161Z"
                            p-id="2421"
                          ></path>
                          <path
                            d="M692.094728 699.901531 625.76709 699.901531 625.76709 751.404532 625.76709 819.291688 691.942255 819.291688 745.156224 819.291688 745.156224 752.963027 692.094728 752.963027 692.094728 699.901531Z"
                            p-id="2422"
                          ></path>
                          <path
                            d="M745.156224 938.679799 813.227576 938.679799 873.868701 938.679799 930.872996 938.679799 930.872996 872.352161 873.868701 872.352161 811.484885 872.352161 811.484885 819.291688 745.156224 819.291688 745.156224 872.688829 745.156224 938.679799Z"
                            p-id="2423"
                          ></path>
                          <path
                            d="M930.872996 514.185782 930.872996 447.859167 873.868701 447.859167 811.484885 447.859167 811.484885 514.185782 873.868701 514.185782 930.872996 514.185782Z"
                            p-id="2424"
                          ></path>
                          <path
                            d="M864.545358 752.963027 930.872996 752.963027 930.872996 819.290665 864.545358 819.290665 864.545358 752.963027Z"
                            p-id="2425"
                          ></path>
                          <path
                            d="M440.052364 328.470033 506.378979 328.470033 506.378979 394.796648 440.052364 394.796648 440.052364 328.470033Z"
                            p-id="2426"
                          ></path>
                          <path
                            d="M745.156224 447.859167l0-53.061496 185.715749 0L930.871973 89.692788 625.76709 89.692788l0 66.326615-55.107085 0-64.281025 0 0 53.061496 64.281025 0 55.108109 0 0 66.326615-53.06252 0 0 53.06252 53.06252 0 0 66.326615 66.325591 0 0 53.061496 53.06252 0L745.156224 447.859167 745.156224 447.859167zM692.094728 156.019403 864.544335 156.019403l0 172.45063L692.094728 328.470033 692.094728 156.019403 692.094728 156.019403z"
                            p-id="2427"
                          ></path>
                          <path
                            d="M692.094728 580.51342 745.156224 580.51342 745.156224 633.573893 692.094728 633.573893 692.094728 580.51342Z"
                            p-id="2428"
                          ></path>
                          <path
                            d="M811.484885 699.901531 745.156224 699.901531 745.156224 752.963027 813.227576 752.963027 864.545358 752.963027 864.545358 690.762383 864.545358 633.573893 930.872996 633.573893 930.872996 580.51342 873.868701 580.51342 811.484885 580.51342 811.484885 630.121258 811.484885 699.901531Z"
                            p-id="2429"
                          ></path>
                          <path
                            d="M440.052364 209.080899 506.378979 209.080899 506.378979 275.407514 440.052364 275.407514 440.052364 209.080899Z"
                            p-id="2430"
                          ></path>
                          <path
                            d="M267.600711 514.185782 320.66323 514.185782 320.66323 580.51342 267.600711 580.51342 267.600711 514.185782Z"
                            p-id="2431"
                          ></path>
                          <path
                            d="M440.052364 447.859167 388.733559 447.859167 320.66323 447.859167 320.66323 514.185782 386.990868 514.185782 386.990868 580.51342 440.052364 580.51342 440.052364 508.837984 440.052364 447.859167Z"
                            p-id="2432"
                          ></path>
                          <path
                            d="M386.990868 819.291688 386.990868 699.901531l53.061496 0 0-66.326615-51.318806 0-187.459462 0 0-64.094784 0-55.293327-55.108109 0L81.885985 514.186805l0 66.326615 66.327638 0 0 53.061496L81.885985 633.574916l0 305.105906 305.105906 0 0-66.327638 53.061496 0 0-53.061496L386.990868 819.291688 386.990868 819.291688zM320.66323 872.352161 148.2126 872.352161 148.2126 699.901531l172.45063 0L320.66323 872.352161 320.66323 872.352161z"
                            p-id="2433"
                          ></path>
                          <path
                            d="M440.052364 580.51342 506.378979 580.51342 506.378979 633.573893 440.052364 633.573893 440.052364 580.51342Z"
                            p-id="2434"
                          ></path>
                          <path
                            d="M625.76709 514.185782 692.094728 514.185782 692.094728 580.51342 625.768113 580.51342 625.768113 514.185782 625.76709 514.185782Z"
                            p-id="2435"
                          ></path>
                          <path
                            d="M745.156224 514.185782 811.483862 514.185782 811.483862 580.51342 745.156224 580.51342 745.156224 514.185782Z"
                            p-id="2436"
                          ></path>
                        </svg>
                      </el-tooltip>
                    </div>

                    <div class="rg-box pdt" style="opacity: 0">
                      <el-button size="mini">转出至账户余额</el-button>
                      <div><el-button size="mini">提现</el-button></div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-card>

          <el-card class="box-card" style="margin: 32px 0">
            <div slot="header" class="clearfix hdt">
              <span class="card-title" style="margin: 0; flex: 1"
                >{{ $t("RewardCenter.AgentAccount.RewardProportion") }}
              </span>
              <div>
                <a
                  class="alink"
                  :href="enOrTw == 'tw' ? 'https://fotoncard.com/FotonAgentDesc.pdf' : 'https://fotoncard.com/FotonAgentDescEN.pdf'"
                  target="_blank"
                  >{{ $t("RewardCenter.AgentAccount.HowIncrease") }}</a
                >
              </div>
            </div>

            <div style="width: 100%">
              <div class="tb-her">
                <div>{{ $t("RewardCenter.AgentAccount.AgentLevel") }}</div>
                <div style="font-size: 26px" class="level">
                  {{ agentAccData.level }}
                </div>
                <!-- 一个S -->
                <!-- <div v-if="agentAccData.level == 'S'">
                        <span style="font-size:26px;color:#00BEA4;padding:0 .1rem">S</span>
                    </div> -->
                <!-- 两个SS -->
                <!-- <div v-if="agentAccData.level == 'SS'">
                        <span style="font-size:26px;color:#00BEA4;padding:0 .1rem">S</span>
                        <span style="font-size:21px;color:#00BEA4;padding:0 .1rem;vertical-align: 2px">S</span>
                    </div> -->
                <!-- 三个SSS -->
                <!-- <div v-if="agentAccData.level == 'SSS'">
                        <span style="font-size:26px;color:#00BEA4;padding:0 .1rem">S</span>
                        <span style="font-size:21px;color:#00BEA4;padding:0 .1rem;vertical-align: 2px">S</span>
                        <span style="font-size:16px;color:#00BEA4;padding:0 .1rem;vertical-align: 4px;">S</span>
                    </div> -->
              </div>
              <table
                cellpadding="0"
                cellspacing="10"
                style="width: 100%; border: 1px solid #ebeef5"
                align="center"
              >
                <th class="thclass">
                  {{ $t("RewardCenter.AgentAccount.UserLevel") }}
                </th>
                <th class="thclass">BIN</th>
                <th class="thclass">
                  {{ $t("RewardCenter.AgentAccount.CardFeeReward")
                  }}<el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('RewardCenter.AgentAccount.CardFeeRewardTip')"
                    placement="top-start"
                  >
                    <i class="el-icon-question qtstyle"></i>
                  </el-tooltip>
                </th>
                <th class="thclass">
                  {{ $t("RewardCenter.AgentAccount.CardTopupReward")
                  }}<el-tooltip
                    class="item"
                    effect="dark"
                    :content="
                      $t('RewardCenter.AgentAccount.CardTopupRewardTip')
                    "
                    placement="top-start"
                  >
                    <i class="el-icon-question qtstyle"></i>
                  </el-tooltip>
                </th>
                <tr
                  v-for="(item, index) in agentAccData.bonus.Level1"
                  :key="index"
                >
                  <td
                    align="center"
                    style="border-top: 1px solid #ebeef5"
                    :rowspan="agentAccData.bonus.Level1.length"
                    v-if="index == 0"
                  >
                    {{ $t("RewardCenter.AgentAccount.DirectPushUser") }}
                    <div style="font-size: 14px">(Layer 1)</div>
                  </td>
                  <td class="tdclass" align="center">{{ item.BIN }}</td>
                  <td class="tdclass" align="center">
                    {{ toPercent(item.CardFee) }}
                  </td>
                  <td class="tdclass" align="center">
                    {{ toPercent(item.TopupFee) }}
                  </td>
                </tr>
                <!-- <tr>
           <td align="center">重量</td>
           <td align="center">单价</td>
           <td align="center">单价</td>
       </tr>
       <tr>
           <td align="center">重量1</td>
           <td align="center">单价1</td>
           <td align="center">单价1</td>
       </tr> -->
                <tr
                  v-for="(item, index) in agentAccData.bonus.Level2"
                  :key="index"
                >
                  <td
                    class="tdclass"
                    align="center"
                    :rowspan="agentAccData.bonus.Level2.length"
                    v-if="index == 0"
                  >
                    {{ $t("RewardCenter.AgentAccount.SecondaryUser") }}
                    <div style="font-size: 14px">(Layer 2)</div>
                  </td>
                  <td class="tdclass" align="center">{{ item.BIN }}</td>
                  <td class="tdclass" align="center">
                    {{ toPercent(item.CardFee) }}
                  </td>
                  <td class="tdclass" align="center">
                    {{ toPercent(item.TopupFee) }}
                  </td>
                </tr>
                <!-- <tr>
           <td align="center" rowspan="3">二级用户</td>
           <td align="center">3公斤</td>
           <td align="center">5元/公斤</td>
           <td align="center">15元</td>
       </tr> -->
                <!-- <tr>
           <td align="center">重量</td>
           <td align="center">单价</td>
           <td align="center">单价</td>
       </tr>
       <tr>
           <td align="center">重量2</td>
           <td align="center">单价3</td>
           <td align="center">单价4</td>
       </tr> -->
              </table>
              <div style="margin: 3rem 0;text-align: center" v-if="agentAccData.bonus.length == 0">
              <h2>
                No items
                <CIcon
                  name="cilBan"
                  style="color: #e53535; width: 30px; height: 30px"
                />
              </h2>
            </div>
            </div>

            <div>
              <!-- <div class="lv">{{ $t("RewardCenter.AgentAccount.AgentLevel") }}{{ agentAccData.level }}</div> -->
              <div style="display: flex; justify-content: center">
                <!-- <div style="display:flex;justify-content:center;flex-direction: column;">
                    <div style="padding: .3rem 3rem;border:1px solid #EBEEF5;font-weight:bold">{{ $t("RewardCenter.AgentAccount.UserLevel") }}</div>
                    <div style="padding: 1.3rem 3rem;border:1px solid #EBEEF5">{{ $t("RewardCenter.AgentAccount.DirectPushUser") }}</div>
                    <div style="padding: 1.3rem 3rem;border:1px solid #EBEEF5">{{ $t("RewardCenter.AgentAccount.SecondaryUser") }}</div>
                </div> -->
                <!-- <div style="display:flex;justify-content:center;flex-direction: column;">
                    <div style="padding: .3rem 3rem;border:1px solid #EBEEF5;font-weight:bold">BIN</div>
                    <div v-for="(item,index) in agentAccData.bonus.Level1" :key="index">
                        <div style="padding: .3rem 3rem;border:1px solid #EBEEF5">{{item.BIN}}</div>
                        <div style="padding: .3rem 3rem;border:1px solid #EBEEF5">472593</div>
                    </div>
                    <div v-for="(item,index) in agentAccData.bonus.Level2" :key="index">
                        <div style="padding: .3rem 3rem;border:1px solid #EBEEF5">{{item.BIN}}</div>
                        <div style="padding: .3rem 3rem;border:1px solid #EBEEF5">472593</div>
                    </div>
                </div> -->
                <!-- <div style="display:flex;justify-content:center;flex-direction: column;">
                    <div style="padding: .3rem 3rem;border:1px solid #EBEEF5;font-weight:bold">{{ $t("RewardCenter.AgentAccount.CardFeeReward") }}<el-tooltip
                          class="item"
                          effect="dark"
                          :content="$t('RewardCenter.AgentAccount.CardFeeRewardTip')"
                          placement="top-start"
                        >
                          <i class="el-icon-question qtstyle"></i>
                        </el-tooltip></div>
                    <div class="tbcol">{{ toPercent(dlAccObj.Level1.BIN428837.CardFee) }}</div>
                    <div class="tbcol">{{ toPercent(dlAccObj.Level1.BIN472593.CardFee) }}</div>
                    <div class="tbcol">{{ toPercent(dlAccObj.Level2.BIN428837.CardFee) }}</div>
                    <div class="tbcol">{{ toPercent(dlAccObj.Level2.BIN472593.CardFee) }}</div>
                </div> -->
                <!-- <div style="display:flex;justify-content:center;flex-direction: column;">
                    <div style="padding: .3rem 3rem;border:1px solid #EBEEF5;font-weight:bold">{{ $t("RewardCenter.AgentAccount.CardTopupReward") }}<el-tooltip
                          class="item"
                          effect="dark"
                          :content="$t('RewardCenter.AgentAccount.CardTopupRewardTip')"
                          placement="top-start"
                        >
                          <i class="el-icon-question qtstyle"></i>
                        </el-tooltip></div>
                    <div class="tbcol">{{ toPercent(dlAccObj.Level1.BIN428837.TopupFee) }}</div>
                    <div class="tbcol">{{ toPercent(dlAccObj.Level1.BIN472593.TopupFee) }}</div>
                    <div class="tbcol">{{ toPercent(dlAccObj.Level2.BIN428837.TopupFee) }}</div>
                    <div class="tbcol">{{ toPercent(dlAccObj.Level2.BIN472593.TopupFee) }}</div>
                </div> -->
              </div>
              <!-- <el-table
      :data="tableData"
      :span-method="objectSpanMethod"
      border
      style="width: 100%; margin-top: 20px">
      <el-table-column
        prop="id"
        label="ID"
        width="180">
      </el-table-column>
      <el-table-column
        prop="name"
        label="姓名">
      </el-table-column>
      <el-table-column
        prop="amount1"
        label="数值 1（元）">
      </el-table-column>
      <el-table-column
        prop="amount2"
        label="数值 2（元）">
      </el-table-column>
    </el-table>

              <el-table
                stripe
                height="250"
                style="margin: 0 auto; background: none"
                :data="dlAccTbData"
              >
                <template slot="empty">
                  <div style="margin: 3rem 0">
                    <h2>
                      No items
                      <CIcon
                        name="cilBan"
                        style="color: #e53535; width: 30px; height: 30px"
                      />
                    </h2>
                  </div>
                </template>
                <el-table-column prop="bins" label="BIN"> </el-table-column>
                <el-table-column prop="CardFee" label="开卡费奖励" :render-header="renderHeader">
                  <template slot-scope="scope">
                    <div>{{ toPercent(Number(scope.row.CardFee)) }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="Topup" label="增值费奖励">
                  <template slot-scope="scope">
                    <div>{{ toPercent(Number(scope.row.Topup)) }}</div>
                  </template>
                </el-table-column>
              </el-table> -->
            </div>
          </el-card>
          <div class="img" id="qrcode" style="opacity: 0">
            <vueQr :text="eSrc"></vueQr>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import vueQr from "vue-qr";
import TextScroll from "../../components/TextScroll.vue"
import { getPageNotice} from "../../api/http"
export default {
  components: { vueQr, TextScroll },
  data() {
    return {
      tot: "",
      dObj: "",
      tbData: [],
      tt: null,
      tos: 0,
      toos: false,
      tableData: [
        {
          id: "12987122",
          name: "王小虎",
          amount1: "234",
          amount2: "3.2",
          amount3: 10,
        },
        {
          id: "12987123",
          name: "王小虎",
          amount1: "165",
          amount2: "4.43",
          amount3: 12,
        },
        {
          id: "12987124",
          name: "王小虎",
          amount1: "324",
          amount2: "1.9",
          amount3: 9,
        },
        {
          id: "12987125",
          name: "王小虎",
          amount1: "621",
          amount2: "2.2",
          amount3: 17,
        },
      ],
      textArr: [
        // {
        //   id:1,
        //   val: '代理中心-代理账户'
        // }
      ],
      isVis: false
    };
  },
  created() {
    this.$store.dispatch("getAgentAcc");
    // console.log("/request/#/pages/register?agent=123456")
    // if(agentAccData.withdraw_account.Bank == undefined) {

    // }
  },
  watch: {
    agentAccData(val) {
      console.log("val==>", val)
      this.tt = val.withdraw_account
      this.tos = reward.total
    },
  },
  computed: {
    ...mapState(["agentAccData", "dlAccTbData", "dlAccObj"]),
    eSrc() {
      return (
        "https://dashboard.fotoncard.com/#/pages/register?agent=" +
        this.agentAccData.code
      );
    },
    tots() {
      return this.agentAccData.reward;
    },
    enOrTw() {
      return this.$i18n.locale
    }
  },
  methods: {
    // 请求通告数据
    getAnnouncement(noticeType) {
      let obj = {
        uid: window.localStorage.getItem("uid"),
        type: "get_page_notice",
        // page: "fund_fund"
        page: noticeType
      }
      getPageNotice(obj).then((res) => {
        console.log("notice==>", res)
        if(res.code == 200) {
          if(res.data.length !== 0) {
            this.isVis = true
            this.textArr = res.data
          }else {
            this.isVis = false
          }
        }
      })
    },
    formatNum(value) {
      if (!value && value !== 0) return 0;

      let str = value.toString();
      let reg =
        str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
      return str.replace(reg, "$1,");
    },
    toWithdrawPage() {
      this.$router.push({
        path: "/reward-center/bind-withdraw-acc",
      });
    },
    // 去到轉出至賬戶餘額頁
    toTransferAcc() {
      this.$router.push({
        path: "/reward-center/transfer-to-account",
      });
    },
    // 去到银行绑定页
    toBankPage() {
      this.$router.push("/reward-center/bind-bank-acc")
    },
    // 去到USDT绑定页
    toUSDTPage() {
      this.$router.push("/reward-center/bind-usdt-acc")
    },
    // 去到代理提現頁
    toAgentWithdraw() {
      if (this.agentAccData.withdraw_account == null || this.agentAccData.withdraw_account.length == 0) {
        this.$confirm(this.$t('RewardCenter.AgentWithdraw.Diglog'), "", {
          confirmButtonText: this.$t("Funds.Inwardrem.TipsButton2"),
          showClose: false,
          showCancelButton: true,
          closeOnClickModal: false,
          type: "warning",
        })
          .then(() => {
            this.$router.push("/reward-center/bind-withdraw-acc");
          })
          .catch(() => {
            // this.$message.error(e)
          });
      } else {
        this.$router.push({
          path: "/reward-center/agent-withdraw",
        });
      }
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      console.log("row==>", row);
      console.log("column==>", column);
      console.log("rowIndex==>", rowIndex);
      console.log("columnIndex==>", columnIndex);
      if (columnIndex === 0) {
        if (rowIndex % 2 === 0) {
          return {
            rowspan: 2,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
    //   https://dashboard.fotoncard.com/#/pages/register?agent=123456
    // render 事件
    renderHeader(h, { column }) {
      // h即为cerateElement的简写，具体可看vue官方文档
      //   <el-tooltip
      //                       class="item"
      //                       effect="dark"
      //                       :content="$t('RewardCenter.AgentAccount.BalanceTip')"
      //                       placement="top-start"
      //
      let that = this > console.log("column", column);
      return h("div", [
        h("span", column.label),
        h("el-tooltip", {
          effect: "dark",
          content: "您的獎勵 = 用戶創建卡產生之費用 X 比例",
          placement: "top-start",
          value: that.toos,
        }),
        h("i", {
          class: "el-icon-view",
          style: "margin-left:20px;cursor: pointer",
          on: {
            click: that.had,
          },
        }),
      ]);
    },
    had() {
      this.tots = true;
    },
    // copy
    getCopy(data) {
      let input = document.createElement("input"); // js创建一个input输入框
      input.value = data; // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input); // 将输入框暂时创建到实例里面
      input.select(); // 选中输入框中的内容
      document.execCommand("Copy"); // 执行复制操作
      document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
      this.$message({
        type: "success",
        message: "Copied",
      });
    },
    // 下载二维码
    downs() {
      const fileName = "1.png";
      let img = document
        .getElementById("qrcode")
        .getElementsByTagName("img")[0].src;

      let aLink = document.createElement("a");
      let blob = this.base64ToBlob(img);
      let evt = document.createEvent("HTMLEvents");
      evt.initEvent("click", true, true); // initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      aLink.download = fileName;
      aLink.href = URL.createObjectURL(blob);
      // aLink.dispatchEvent(evt);
      aLink.click();
    },
    //base64转blob
    base64ToBlob(code) {
      let parts = code.split(";base64,");
      let contentType = parts[0].split(":")[1];
      let raw = window.atob(parts[1]);
      let rawLength = raw.length;
      let uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: contentType });
    },
    // 小数转百分比显示
    toPercent(point) {
      if (point == 0) {
        return 0;
      }
      var str = Number(point * 100).toFixed(2);
      str += "%";
      return str;
    },
  },
};
</script>

<style scoped>
.level {
  background-image: -webkit-linear-gradient(45deg, #91febd, #194e72);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.sbtn:hover {
  background: rgba(0, 190, 164, 0.8) !important;
}
.sbtn:focus {
  border-color: #00bea4;
  box-shadow: 0 0 0 0.25rem rgba(0, 190, 164, 0.5) !important;
}
.custer {
  background: #00bea4;
  color: #fff;
  width: 100%;
  outline: 0;
}
/*  */
.cur {
  outline: none;
  border: none;
  color: #00bea4;
  background: transparent;
  padding: 6px 12px;
  font-weight: bold;
}
.hv:hover {
  background: rgb(0, 190, 164);
  color: #fff;
  border-radius: 0.375rem;
}
.hv:focus {
  background: rgb(0, 190, 164);
  color: #fff;
  border-radius: 0.375rem;
}
.hdt {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.alink {
  font-size: 0.2rem;
  cursor: pointer;
  color: #1f7ad4;
}
.tb-her {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  font-weight: bold;
  align-items: center;
}
.tdclass {
  padding: 10px 0;
  border: 1px solid #ebeef5;
}
.thclass {
  padding: 10px 0;
  text-align: center;
}
.tbcol {
  padding: 0.3rem 3rem;
  border: 1px solid #ebeef5;
  text-align: center;
}
.lv {
  display: flex;
  justify-content: center;
  /* padding: 0.3rem 15.9rem; */
  /* border: 1px solid #EBEEF5; */
  /* width: 671px; */
  margin: 0 auto;
  font-weight: bold;
  padding-bottom: 0.5rem;
}
.txacc {
  display: flex;
  align-items: center;
}
.pdt {
  padding-top: 20px;
}
.hideys {
  display: none;
}
.rg-box {
  display: flex;
  justify-content: start;
}
.it-box {
  width: 520px;
  margin: 0 auto;
  display: flex;
}
.left-box {
  width: 50%;
}
.right-box {
  width: 50%;
  text-align: right;
}
.tit {
  width: 150px;
  text-align: right;
  color: #42474d;
  font-size: 14px;
  font-family: AxLLCircular, Helvetica, Arialsans-serif;
}
.qtstyle {
  color: #a196aa;
  cursor: pointer;
  margin-left: 0.33rem;
}
.dl-acc {
  padding-left: 2.6rem;
  height: 100%;
  background: #fff;
}
.dlitem-val {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cr-box {
  width: 83%;
  margin: 0 auto;
  overflow: hidden;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.item-t {
  color: #3c2b2b;
  /* font-weight: bold; */
  padding-bottom: 1.25rem;
  /* width: 50%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.item-v {
  color: #a196aa;
  font-size: 18px;
  font-weight: bold;
  width: 150px;
  /* padding-bottom: 1.25rem; */
}
.card-title {
  font-weight: bold;
  font-size: 1.1rem;
}
</style>